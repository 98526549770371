.administrationActions-row-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #dcdee1;
    margin-top: 20px;
}

.administrationActions-row-actions-tabs {
    display: flex;
    flex-direction: row;
}

.administrationActions-row-actions-tab {
    margin-bottom: -1px;
    line-height: 40px;
    margin-right: 40px;
    cursor: pointer;
    padding-top: 10px;
}