.administrationTable-wrapper {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(34, 60, 80, 0.3);
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: stretch;
    overflow-y: scroll;
}

.administrationTable-header-wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dcdee1;
    padding: 20px 20px;
}

.administrationTable-table-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.administrationTable-table-row-wrapper {
    display: flex;
    flex-direction: row;
    padding: 20px 20px;
}

.administrationTable-table-row-wrapper:hover {
    cursor: pointer;
    background-color: #f3f4f5;
}

.administrationTable-header-cell-wrapper {
    width: 15%;
    margin-right: 1%;
    position: relative;
    display: flex;
    flex-direction: row;
}

.administrationTable-table-row-cell-wrapper {
    width: 15%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    margin-right: 1%;
}
