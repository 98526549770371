.loginPageDesktop-wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
}

.loginPageDesktop-left-wrapper {
    background-color: #f1f2f3;
    background-image: url('../../../assets/images/login.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 45%;
    width: 50%;
}

.loginPageDesktop-column-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 50px 0px;
}

.loginPageDesktop-form-wrapper {
    width: 25vw;
}

.loginPageDesktop-form-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 40px;
}

.loginPageDesktop-form-title-text {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #384650;
}
  
.loginPageDesktop-form-input-label {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    margin-bottom: 10px;
}

.loginPageDesktop-form-button {
    width: 100%;
    height: 40px!important; 
    margin-top: 20px;
    font-size: 18px!important;
}

.loginPageDesktop-logo {
    width: 200px;
    margin-bottom: 50px;
}