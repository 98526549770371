.sideBarButton-icon {
    font-size: 30px;
    color: inherit;
}

.sideBarButton-wrapper {
    cursor: pointer;
    padding: 10px 0px;
    border-left: 7px solid #fff;
    color: #c4c9d5;
    border-right: 7px solid #fff;
}

@media (min-width: 1024px) {
    .sideBarButton-icon {
        font-size: 22px;
    }
}

@media (min-width: 1440px) {
    .sideBarButton-icon {
        font-size: 30px;
    }
}

@media (min-width: 2560px) {
    .sideBarButton-icon {
        font-size: 34px;
    }
}