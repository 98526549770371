.mailingPageDesktop-wrapper {
    background-color: #f7f8fa;
    height: 100%;
    padding: 20px 40px 40px;
    overflow-y: scroll;
    height: 100vh;
}

.mailingPageDesktop-title {
    color: #212121;
    font-size: 30px;
    margin-bottom: 20px;
}

.mailingPageDesktop-section-title {
    color: #212121;
    font-size: 18px;
    margin-bottom: 10px;
}

@media (min-width: 1024px) {
    .mailingPageDesktop-title {
      font-size: 32px;
    }
    .mailingPageDesktop-section-title {
      font-size: 18px;
  }
}

@media (min-width: 1440px) {
    .mailingPageDesktop-title {
      font-size: 40px;
    }
    .mailingPageDesktop-section-title {
      font-size: 30px;
  }
}

@media (min-width: 2560px) {
    .mailingPageDesktop-title {
      font-size: 60px;
    }
    .mailingPageDesktop-section-title {
      font-size: 50px;
  }
}